<template>
  <div class="container">
    <h1 class="group-name" v-if="!targetGroups">{{ $t('easterEggs.title') }}</h1>
    <!-- <div class="dropdown">
      <VWDropdown :options="options" v-model="selectedOption" :show-labels="false" placeholder="Select category" />
    </div> -->
    <div v-for="(group, index) in targetGroups" :key="index">
      <div
        v-if="
          (!selectedOption || selectedOption === group.name || selectedOption === 'Select All') &&
          group.name !== 'Trivia'
        "
      >
        <h1 class="group-name">{{ isFrench ? group.nameFR : group.name }}</h1>
        <span class="stamps-found">{{ group.completedTargets }}/{{ group.targets.length }}</span>
        <span class="stamps-found"> {{ $t('easterEggs.eggsFound') }}</span>
        <div class="stamps-container">
          <div
            v-for="(stamp, index) in group.targets"
            :key="index"
            class="box-1 clue-placer"
            @click="showHintIfPresent(stamp)"
          >
            <router-link v-if="stamp.complete" :to="`/target/${stamp.id}`">
              <img class="stamps-images complete" :src="getStampUrl(stamp.iconSrc)" />
            </router-link>
            <img v-else class="stamps-images" :src="getStampUrl(stamp.iconSrc)" />
            <Clue v-if="shouldHintDisplay(stamp)" />
          </div>
        </div>
        <!-- <hr class="divider" /> -->
      </div>
    </div>
    <div v-if="selectedHint" class="modal-wrap">
      <div class="modal">
        <Hint class="modal-content" :title="hintTitle" :text="hintText" @close="selectedHint = null" />
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/api.service';
import Clue from '@/components/Clue.vue';
import Hint from '@/components/Hint.vue';
// import VWDropdown from '@/components/VWDropdown.vue';

export default {
  name: 'Login',
  components: {
    Clue,
    Hint,
  },
  data() {
    return {
      showNav: false,
      selectedOption: null,
      selectedHint: null,
      options: ['Select All'],
      targetGroups: null,
    };
  },
  computed: {
    hintTitle() {
      const { locale } = this.$store.state;
      if (locale === 'fr' && this.selectedHint.hintTitleFR) return this.selectedHint.hintTitleFR;
      return this.selectedHint.hintTitle;
    },
    hintText() {
      const { locale } = this.$store.state;
      if (locale === 'fr' && this.selectedHint.hintTextFR) return this.selectedHint.hintTextFR;
      return this.selectedHint.hintText;
    },
    isFrench() {
      return this.$store.state.locale === 'fr';
    },
  },
  mounted() {
    this.getTargetGroups();
  },
  methods: {
    getStampUrl(slug) {
      return `${process.env.VUE_APP_ASSET_URL}/${slug}`;
    },
    async getTargetGroups() {
      await ApiService.get(
        '/targetGroup/?limit=10&offset=0&includeTargets=true&includeDraftGroups=false&includeDraftTargets=false',
      ).then((response) => {
        this.targetGroups = response.data.targetGroups;
        response.data.targetGroups.forEach((targetGroup) => {
          this.options.push(this.isFrench ? targetGroup.nameFR : targetGroup.name);
        });
      });
      this.targetGroups.forEach((targetGroup) => {
        const completedTargets = targetGroup.targets.filter((target) => target.complete);
        this.$set(targetGroup, 'completedTargets', completedTargets.length);
      });
    },
    shouldHintDisplay(stamp) {
      return (stamp.hintTitle || stamp.hintText) && !stamp.complete;
    },
    showHintIfPresent(stamp) {
      if (this.shouldHintDisplay(stamp)) {
        this.selectedHint = stamp;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stamps-heading {
  font-size: 34px;
  font-weight: normal;
  letter-spacing: -0.85px;
}
.dropdown {
  width: 85%;
  font-weight: bold;
  @include bp-sm-phone-landscape {
    width: 470px;
  }
}
.stamps-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // width: 95%;
  // margin: 0 auto;
  margin-top: 45px;
  @include bp-sm-phone-landscape {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.group-name {
  margin-bottom: 17px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.42px;
}
.stamps-images {
  display: block;
  width: 100%;
  filter: opacity(50%) grayscale(1);
  &.complete {
    filter: none;
  }
}
.clue-placer {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 150px;
  height: 150px;
  // border: 2px solid #fff;
  padding: 24px;
}
// .stamps-found {
//   font-size: 12px;
//   text-transform: uppercase;
//   letter-spacing: 0.6px;
//   &__bold {
//     font-weight: bold;
//   }
// }
.divider {
  width: 55%;
  margin-top: 1em;
}

.logo {
  display: block;
  width: 2.5em;
  margin-top: 2em;
  margin-right: auto;
  margin-bottom: 8em;
  margin-left: auto;
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  min-height: calc(100vh - 73px);
  background: rgba(36, 58, 83, 0.8);
  backdrop-filter: blur(5px);
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: 291px;
    max-height: 90%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    // max-height: 479.406px;
    // .modal-content {
    //   overflow-y: scroll;
    // }
  }
}
</style>
