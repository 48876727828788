<template>
  <div class="clue">
    <span>{{ $t('easterEggs.clue') }}</span>
  </div>
</template>

<script>
export default {
  name: 'Clue',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.clue {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50.35px;
  height: 21.19px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background: #f6b619;
  box-shadow: 0 3px 6px #00000029;
}
</style>
