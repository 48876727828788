<template>
  <div class="hint-wrap">
    <XIcon class="x-icon" @click="pushBack" />
    <div class="hint">
      <HintBulbIcon class="hint-bulb-icon" />
      <h1>{{ title || $t('easterEggs.hint') }}</h1>
      <!-- <hr class="separator" /> -->
      <p class="paragraph">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import XIcon from '@/assets/icons/x_icon.svg';
import HintBulbIcon from '@/assets/ford/Hint_Bulb.svg';

export default {
  name: 'Hint',
  components: {
    XIcon,
    HintBulbIcon,
  },
  props: {
    title: String,
    text: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    pushBack() {
      // this.$router.back();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.hint-wrap {
  position: relative;
  color: #fff;
  background-color: #243a53;
}
.hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin: 0;
  }
  p {
    margin-top: 27px;
    margin-bottom: 63px;
  }
}
.paragraph {
  width: 80%;
  font-size: 18px;
  text-align: center;
}
.x-icon {
  position: fixed;
  top: 27px;
  right: 27px;
  width: 20px;
}
.hint-bulb-icon {
  width: 140px;
  margin-top: 77px;
  margin-bottom: 53px;
}
.separator {
  width: 6em;
}
</style>
